// For dev, _HOST should be the host:port of the backend (Django) server
// For production, _HOST should be set from environment variable VITE_API_HOST

const API_VERSION = 'v1_15';
export let API_HOST = '';
let COMMUNITY_HOST = '';

// Set the API Host from environment variables set during build
// Default to secure protocols (production) but optionally allow others
const ENV_PROTOCOL = import.meta.env.VITE_API_PROTOCOL;
const ENV_HOST = import.meta.env.VITE_API_HOST;
const ENV_COMMUNITY_PROTOCOL = import.meta.env.VITE_COMMUNITY_PROTOCOL;
const ENV_COMMUNITY_HOST = import.meta.env.VITE_COMMUNITY_HOST;

// Set the base URL for the API
if (ENV_HOST) {
  if (ENV_PROTOCOL) {
    API_HOST = ENV_PROTOCOL + '://' + ENV_HOST;
  } else {
    API_HOST = 'https://' + ENV_HOST;
  }
}

// Set the base URL for the Community frontend
if (ENV_COMMUNITY_HOST) {
  if (ENV_COMMUNITY_PROTOCOL) {
    COMMUNITY_HOST = ENV_COMMUNITY_PROTOCOL + '://' + ENV_COMMUNITY_HOST;
  } else {
    COMMUNITY_HOST = 'https://' + ENV_COMMUNITY_HOST;
  }
}

// If we are in development mode, default API host to http://localhost:8000 
// and community host to http://localhost:3001

if (process.env.NODE_ENV === 'development') {
  // Enable API/Community usage via ngrok
  // _API_HOST = 'http://cabinet-api-1.ngrok.io';
  // _COMMUNITY_HOST = 'http://cabinet-community-1.ngrok.io';

  const ngrokIdMatch = window.location.hostname.match(/^.*cabinet-app-([0-9]+)\.ngrok.io.*/);
  if (ngrokIdMatch) {
    const ngrokId = ngrokIdMatch[1];
    const protocol = window.location.protocol;
    
    API_HOST = `${protocol}//cabinet-api-${ngrokId}.ngrok.io`;
    COMMUNITY_HOST = `${protocol}://cabinet-community-${ngrokId}.ngrok.io`;
  }

  if (!API_HOST) {
    API_HOST = 'http://localhost:8000';
  }
  if (!COMMUNITY_HOST) {
    COMMUNITY_HOST = 'http://localhost:3001';
  }
}

const API_PREFIX = '/api/' + API_VERSION + '/';
const PREFIX = API_HOST + API_PREFIX;

export const API_URLS = {
  // Cabinet API
  TERMS_OF_SERVICE: PREFIX + 'core/terms-of-service/',
  USER_ACCEPT_TERMS_OF_SERVICE: PREFIX + 'core/accept-terms-of-service/',
  AUTH_LOGIN: PREFIX + 'core/auth/login/',
  AUTH_REFRESH_TOKENS: PREFIX + 'core/auth/refresh_tokens/',
  AUTH_FORGOT_PASSWORD: PREFIX + 'core/auth/forgot_password/',
  AUTH_CONFIRM_FORGOT_PASSWORD: PREFIX + 'core/auth/confirm_forgot_password/',
  AUTH_LOGOUT: PREFIX + 'core/auth/logout/',
  AUTH_USER: PREFIX + 'core/auth/user/',
  AUTH_IDENTITY: PREFIX + 'core/auth/identity/',
  CHANGE_EMAIL: PREFIX + 'core/auth/change-email/',
  UPDATE_USER: PREFIX + 'core/auth/update_user/',
  USER_PREFS: PREFIX + 'core/user_prefs/', 
  VALIDATE_EMAIL: PREFIX + 'core/auth/validate_email/',
  TRACK_REFERRAL: PREFIX + 'core/referral/track-referral-signup/',
  SAVE_OAUTH_GRANT: PREFIX + 'app-core/oauth/save_grant/',
  MS_LOGIN_URL: PREFIX + 'app-core/oauth/ms-login/',
  PUBLIC_ORG_URL: (org_code: string) => PREFIX + `core/org/${org_code}/public-org/`,
  PUBLIC_ORG_BY_DOMAIN_URL: (domain: string) => PREFIX + `core/org/public-org-by-domain/${domain}/`,
  PUBLIC_ORG_BY_USER_URL: (userId: number) => PREFIX + `core/org/public-org-by-user/${userId}/`,
  ZOOM_LOGIN_URL: PREFIX + 'app-core/oauth/zoom-login/',
  SALESFORCE_LOGIN_URL: PREFIX + 'app-core/oauth/salesforce-login/',
  LOGOUT_OAUTH: PREFIX + 'app-core/oauth/logout/',

  ZOOM_SCHEDULERS: PREFIX + 'app-scheduling/zoom-schedulers/',
  ZOOM_CONTACTS: PREFIX + 'app-scheduling/zoom-contacts/',
  ORG_USER_AUTOCOMPLETE: PREFIX + 'core/org_user_autocomplete/',
  SHARED_LEADERS: PREFIX + 'app-core/shared_leaders/',

  MY_SUBSCRIPTION_DETAILS: PREFIX + 'core/my_subscription_details/',
  SUBSCRIPTION_DETAILS: PREFIX + "core/subscription_details/",
  PAYMENT_SESSION: PREFIX + "core/payment_session/",
  BILLING_SESSION: PREFIX + "core/customer_portal/",
  CHANGE_SUBSCRIPTION: PREFIX + "core/change_subscription/",

  INDUSTRIES: PREFIX + 'core/industries/',
  COUNTRIES: PREFIX + 'core/countries/',
  AIRLINES: PREFIX + 'app-travel/airlines/',
  HOTELS: PREFIX + 'app-travel/hotels/',

  LEADERS: PREFIX + 'app-core/leaders/',
  BATCH_UPDATE_LEADERS: PREFIX + 'app-core/leaders/batch_update/',
  LEADER_PIC_POST_URL: PREFIX + 'app-core/leaders/pic_post_url/',
  BATCH_REORDER_LEADERS: PREFIX + 'app-core/leaders/batch-reorder/',

  ORGANIZATION: (id?: number) => PREFIX + "core/org/" + (id ? `${id}/` : ""),
  ORGANIZATION_PROCESS_LOGO: PREFIX + "core/org/process-logo/",

  AIRLINE_NOTES: PREFIX + 'app-travel/airline-notes/',
  PERSONAL_NOTES: PREFIX + 'app-personal/personal-notes/',
  HOTEL_NOTES: PREFIX + 'app-travel/hotel-notes/',
  PROFILE: PREFIX + 'core/profiles/',
  PROFILE_IMG_POST_URL: PREFIX + 'core/profiles/upload_img_url/',
  PROFILE_CATEGORIES: PREFIX + 'app-profiles/categories/',
  PROFILE_NOTES: PREFIX + 'app-profiles/notes/',

  AVAILABLE_CALENDARS: PREFIX + 'app-scheduling/remote-calendars/',
  GET_ADDITIONAL_CALENDAR: PREFIX + 'app-scheduling/calendars/get-calendar-by-email/',
  UPDATE_ADDITIONAL_CALENDAR: PREFIX + 'app-scheduling/calendars/update-additional-calendar/',
  MS_AVAILABLE_CALENDARS_BY_EMAIL: PREFIX + 'app-scheduling/remote-calendars/add-ms-delegate-calendar-by-email',
  MEETINGS: PREFIX + 'app-scheduling/meetings/',
  MEETING_EXTERNAL: PREFIX + 'app-scheduling/meetings/get-external/',
  MEETING_QUESTION_ANSWER: (external_id: string, email?: string) =>
    PREFIX + `app-scheduling/meeting-question-answers/get-external-meeting-answers/?external_id=${
      external_id
    }` + (email ? `&email=${email}` : ''),
  MULTIPLE_MEETING_QUESTION_ANSWER: (ids: number[]) =>
    PREFIX + `app-scheduling/meeting-question-answers/get-by-meetings/?ids=${ids.join(",")}`,
  MEETING_QUESTION_ANSWER_SUBMIT: PREFIX + 'app-scheduling/meeting-question-answers/submit-answers/',
  MEETING_SLOTS: PREFIX + 'app-scheduling/meeting-slots/',
  MEETING_SLOT_CALENDAR_EVENTS: PREFIX + 'app-scheduling/meeting-slot-calendar-events/',
  MEETING_SLOT_CALENDAR_EVENTS_ERRORS: PREFIX + 'app-scheduling/meeting-slot-calendar-events/meeting-errors/',
  MEETING_EXTERNAL_PARTICIPANT: PREFIX + 'app-scheduling/meeting-external-participants/',
  MEETING_LEADER: PREFIX + 'app-scheduling/meeting-leaders/',
  PARTICIPANT_AUTOCOMPLETE: PREFIX + 'app-scheduling/participant-autocomplete/',
  PRESET_LOCATIONS: PREFIX + 'app-scheduling/meeting-location-presets/',

  CALENDAR_ASSOCIATIONS: PREFIX + 'app-scheduling/calendars/',
  CALENDAR_ASSOCIATION_TOGGLE: PREFIX + 'app-scheduling/calendars/toggle-associate/',
  CALENDAR_ASSOCIATION_BATCH: PREFIX + 'app-scheduling/calendars/batch-associate/',
  CALENDAR_EVENTS: PREFIX + 'app-scheduling/remote-calendar-events/',  
  ZOOM_SETTINGS: PREFIX + 'app-scheduling/zoom-settings/',
  SCHEDULING_PREFS: PREFIX + 'app-scheduling/preferences/',
  SEND_FEEDBACK: PREFIX + 'metrics/feedback/',

  MEETING_QUESTIONS: PREFIX + 'app-scheduling/meeting-questions/',
  MEETING_QUESTIONS_SUBMIT: PREFIX + 'app-scheduling/meeting-questions/submit-questions/',
  MEETING_ROOMS: PREFIX + 'app-scheduling/meeting-rooms/',
  MEETING_AUDIT_LOGS: PREFIX + 'app-scheduling/meeting-audit-logs/',
  SAVE_DEVICE_TOKEN: PREFIX + 'app-core/devices/', //"core/auth/device-token/",
  BOOK_EXTERNAL: PREFIX + 'app-scheduling/meetings/book-external/',
  CANCEL_EXTERNAL: PREFIX + 'app-scheduling/meetings/cancel-external/',
  DUPLICATE_MEETING: (id: number) => PREFIX + `app-scheduling/meetings/${id}/duplicate-meeting/`,

  POLL_SELECTIONS: PREFIX + 'app-scheduling/meeting-poll-selections/',
  DELETE_POLL_SELECTIONS: PREFIX + 'app-scheduling/meeting-poll-selections/delete-poll-selections/',
  UPDATE_POLL_SELECTIONS: PREFIX + 'app-scheduling/meeting-poll-selections/bulk-update-poll-selections/',
  MEETING_FILE: PREFIX + 'app-scheduling/meeting-files/',

  COMMUNITY_QUESTIONS: PREFIX + 'questions/questions/',
  ORGANIZATION_LICENSES: (id?: number) => PREFIX + "core/org_licenses/" + (id ? `${id}/` : ''),

  FETCH_ANALYTICS_EVENTS:  (id?: number) => PREFIX + `app-analytics/events/${id ? id.toString() + "/" : ""}`,
  FETCH_ANALYTICS_EXECUTIVE_CONTACT:  (id?: number) =>
    PREFIX + `app-analytics/executive-contacts/${id ? id.toString() + "/" : ""}`,
  FETCH_CONSOLIDATED_LOCATIONS: PREFIX + 'app-analytics/consolidated-locations/',
  FETCH_EVENT_CATEGORIES: (id?: number) =>
    PREFIX + `app-analytics/event-categories/${id ? id.toString() + "/" : ""}`,
  FETCH_EVENT_TYPES: (id?: number) =>
    PREFIX + `app-analytics/event-types/${id ? id.toString() + "/" : ""}`,
  FETCH_EXECUTIVE_CONTACT_CATEGORIES: (id?: number) => 
    PREFIX + `app-analytics/executive-contact-categories/${id ? id.toString() + "/" : ""}`,
  FETCH_EVENT_ANALYSIS:  PREFIX + 'app-analytics/events/graph-analytics/',
  FETCH_CONTACT_ANALYSIS:  PREFIX + 'app-analytics/executive-contacts/attendee-analytics/',
  FETCH_RECURRENCE_TYPES:  PREFIX + 'app-analytics/recurrence-types/',
  FETCH_EXECUIVE_CONTACT_TYPES: PREFIX + 'app-analytics/executive-contact-types/',
  FETCH_ANALYSIS_INCLUSIONS: PREFIX + 'app-analytics/analysis-inclusions/',
  FETCH_ANALYTICS_COLORS: (id?: number) => PREFIX + `app-analytics/analytics-colors/${id ? id.toString() + "/" : ""}`,
};

export const OTHER_URLS = {
  FETCH_LATEST_VERSION: "/version.txt",
};

export const COMMUNITY_PATHS: {[key: string]: string} = {
  REGISTER: '/register',
  SIGNUP: '/signup', // This is the new signup flow
  ACCOUNT_SETTINGS: '/account',
  PROFILE: '/profile',
  LOGIN: '/login',
  HOME: '/',
  ROOT: '',
};
