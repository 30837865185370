import { UnknownAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, RootAction } from '.';

export type ThunkResult<R> = ThunkAction<R, RootState, null, RootAction>;
export type ThunkDispatchType = ThunkDispatch<RootState, null, UnknownAction | RootAction>;

export const successCodes = [200, 201, 202, 203, 205, 206, 207, 208, 226];
export const successCodesReadOnly = [200, 201, 202, 203, 205, 206, 207, 208, 226] as const;

export type SuccessCode = typeof successCodesReadOnly[number];

export const successNoResponseCodes = [204];
export const successNoResponsReadOnly = [204] as const;

export type SuccessNoResponseCode = typeof successNoResponsReadOnly[number];

export const errorClientCodes = [
  400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418,
  421, 422, 423, 424, 425, 426, 428, 429, 431, 451, 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511,
  999 // <-- this is not a server error but used for network error response when fetch fails
] as const;

export type ClientErrorCode = typeof errorClientCodes[number];

export type FetchReturnError = string | string []
| Record<string, string | string[] | Record<string, string | string[]>>;

export interface FetchReturnSuccess<T> {
  status: SuccessCode;
  data: T;
}

export interface FetchReturnClientError<E extends FetchReturnError> {
  status: ClientErrorCode;
  data: {
    detail: E
    code: string
  }
}

export interface FetchReturnSuccess204 {
  status: SuccessNoResponseCode
}

// TODO: we should remove this any and make T required whenever this is used
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchReturn<T=any, E extends FetchReturnError = string> = 
FetchReturnSuccess<T> | FetchReturnClientError<E> | FetchReturnSuccess204;

export interface ValidationField {
  valid: boolean; 
  detail?: string;
}

export interface CustomFields {
  [key: string]: {value: string; order: number};
}

export enum SESSION_TIMEOUT_OPTION { 
  SESSION_TIMEOUT = "org_session_timeout",
  SESSION_TIMEOUT_NEW_DAY = "org_session_timeout_new_day"
}

export type PaginatedResponse<T> = {
  meta: {
    count: number,
    next: string | null,
    previous: string | null,
    id_order: number[]
  },
  data: T
};

export type AutocompleteRecord = {
  name: string
  email: string
  label: string
};