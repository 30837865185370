import { 
  Leader, Auth, User, Profile,
  Airline,  Hotel,
  HotelNote, AirlineNote, PersonalNote,
  GlobalMessage,
  Feedback,
} from '../store';
import { LeaderPermissions, LeaderPermission } from './leaders/types';
import { IdentityProvider } from './auth/types';
import { ORGANIZATION_PERMISSION } from './organization/types';
import { DEFAULT_LEADER_LABEL_COLORS, FLOW_STATE, OLD_TIER, USER_ROLE } from '../constants';
import { ProfileCategory, ProfileNote } from './profileNotes/types';
import { CABINET_ICONS, EMOJIS } from '../icons';
import cloneDeep from 'lodash/cloneDeep';
import { MeetingSlot, RecurringTimeOptionLimit, RecurringTimes } from './schedule/types';
import { DateTime } from 'luxon';


const getProfileTemplate = (): Profile => {
  const profile: Profile = {
    id: -1,
    user: -1,
    user_leader: -1,
    company: '',
    industry: -1,
    industry_display: '',
    role: '',
    about_me: '',
    valid_pic_url: '',
    referral_url: '',
    country: -1,
    country_code: '',
    location_display: '',
    postal_code: '',
    state: '',
    state_short: '',
    city: '',
    expertise_areas: [''],
    source: '',
    moderator: false,
    app_onboarding_completed: false,
    reference_type: null
  };
  return cloneDeep(profile);
};

export const getUserTemplate = (): User => {
  const user: User = {
    id: -1,
    first_name: '',
    last_name: '',
    email: '',
    profile: getProfileTemplate(),
    stats: { 
      id: -1,
      points: -1,
      top_contributor: false
    },
    prefs: {
      id: -1,
      user: -1,
      task_time_track_remind: true,
      profile_searchable: true,
      due_email: true,
      due_push: true,
      digest_email: true,
      digest_push: true,
      digest_all: true
    },
    active_license: {
      id: -1,
      user: null,
      user_role: USER_ROLE.ASSISTANT,
      name: "",
      email: "",
      organization: -1,
      is_active: false,
      tier: OLD_TIER.BASIC,
      license_tier: -1, 
      org_permissions_groups: [ORGANIZATION_PERMISSION.ANALYTICS_LABELS],
      created_by: null,
    },
    licenses: {},
    features: {},
    permissions:{},
    oauth_grant_details: {
      google: {},
      microsoft: {},
      zoom: {}
    },
    identity_provider: IdentityProvider.COGNITO,
    tos_acceptance_required: false
  };
  return cloneDeep(user);
};

export const getAuthTemplate = (): Auth => {
  const auth: Auth = {
    flowState: FLOW_STATE.SIGNED_OUT,
    challenge: {name: 'string',
      session: '',
      params: {'': ''}},
    isAuthenticated: true,
    isLoading: false,
    isOnboardingInitialized: false,
    user: getUserTemplate(),
    email: '',
    showCongrats: false,
    moderator: false,
    showOnboarding: false,
    isRegistrationSaving: false,
    redirectUrl: null
  };
  return cloneDeep(auth);
};

export const getAirlineNoteTemplate = (): AirlineNote => {
  const airline = getAirlineTemplate();
  const airlineNote: AirlineNote = {
    id: -1,
    airline_data: airline,
    leader: -1,
    username: '',
    password: '',
    loyalty_number: '',
    notes: '',
    custom: {
    }
  };
  return cloneDeep(airlineNote);
};

export const getHotelNoteTemplate = (): HotelNote => {
  const hotel = getHotelTemplate();
  const hotelNote: HotelNote = {
    id: -1,
    hotel_data: hotel,
    leader: -1,
    username: '',
    loyalty_number: '',
    notes: '',
    password: '',
    custom: {
    }
  };
  return cloneDeep(hotelNote);
};

export const getHotelTemplate = (): Hotel => {
  const hotel = {
    id: -1,
    name: '',
    icon_url: '',
    phone_number: '',
    website_url: '',
    is_default: false,
  };
  return cloneDeep(hotel);
};

export const getLeaderPermission = (viewOnly = true): LeaderPermission => {
  return {view: false, edit: !viewOnly, delete: !viewOnly};
};

export const getLeaderPermissions = (viewOnly = true): LeaderPermissions => {
  const permissions: LeaderPermissions = {
    is_admin: false,
    standard: {
      basic: getLeaderPermission(viewOnly),
      airlines: getLeaderPermission(viewOnly),
      hotels: getLeaderPermission(viewOnly),
      personal: getLeaderPermission(viewOnly)
    },
    categories: {},
    scheduling: { create: false, view: false, edit: false, delete: false, add_to_meeting: false },
  };
  return cloneDeep(permissions);
};

export const getLeaderTemplate = (): Leader => {
  const leader: Leader = {
    id: -1,
    first_name: '',
    last_name: '',
    email: '',
    primary_assistant: -1,
    phone_number: '',
    company: '',
    industry: -1,
    industry_display: '',
    role: '',
    pic_url: '',
    country: -1,
    country_code: '',
    location_display: '',
    postal_code: '',
    state: '',
    state_short: '',
    city: '',
    custom: {},
    is_shared: false,
    shared_by: null,
    color: DEFAULT_LEADER_LABEL_COLORS[0],
    permissions: getLeaderPermissions(true),
    order: -1,
    leader_calendars: {},
  };
  return cloneDeep(leader);
};

export const getAirlineTemplate = (): Airline => {
  const airline: Airline = {
    id: -1,
    name: '',
    icon_url: '',
    iata_code: '',
    phone_number: '',
    website_url: '',
    is_default: false
  };
  return cloneDeep(airline);
};

export const getPersonalNoteTemplate = (): PersonalNote => {
  const personalNote: PersonalNote = {
    id: -1,
    leader: -1,
    full_name: '',
    email: '',
    phone_number: '',
    known_traveler: '',
    birthday: '',
    health_insurance: '',
    address: '',
    airline_seat_preference: '',
    notes: '',
    relation: '',
    custom: { },
    is_leader: false
  };
  return cloneDeep(personalNote);
};

export const getGlobalMessageTemplate = (): GlobalMessage => {
  const globalMessage: GlobalMessage = {
    timeout: 0,
    message: '',
    autoDismiss: true,
    header: '',
    position: undefined,
    active: false,
    severity: "info",
  };
  return cloneDeep(globalMessage);
};

export const getFeedbackTemplate = (): Feedback => {
  const feedback: Feedback = {
    message: '',
    anonymous: false,
    extra_data: {}
  };
  return cloneDeep(feedback);
};

export const getProfileCategoryTemplate = (): ProfileCategory => {
  const category: ProfileCategory = {
    id: -1,
    leader: -1,
    title: '',
    icon: EMOJIS.DEFAULT_CATEGORY,
    icon_url: CABINET_ICONS.FOLDER.icon_url,
    order: -1,
    notes: [],
  };
  return cloneDeep(category);
};

export const getProfileNoteTemplate = (): ProfileNote => {
  const note: ProfileNote = {
    id: -1,
    profile_category: -1,
    title: '',
    subtitle: '',
    image_url: '',
    notes: '',
    header_fields: {},
    custom: {},
  };
  return cloneDeep(note);
};

export const getMeetingSlotTemplate = (): MeetingSlot => {
  const slot: MeetingSlot = {
    id: -1,
    meeting: -1,
    start_date: '',
    end_date: '',
    selfResponse: "accepted",
    is_exclude: false
  }; 
  return JSON.parse(JSON.stringify(slot));
};

export const getDefaultRecurringTimesTemplate = (): RecurringTimes => {
  return {
    days: {
      mon: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 0,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: true
      },
      tue: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 1,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: true
      },
      wed: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 2,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: true
      },
      thu: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 3,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: true
      },
      fri: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 4,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: true
      },
      sat: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 5,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: false
      },
      sun: {
        rules: [{
          rrule: {
            freq: 'WEEKLY',
            byweekday: 6,
            time: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toISO()
          },
          duration: 28800
        }],
        enabled: false
      },
    },
    options: {
      limit: {
        selected: RecurringTimeOptionLimit.NUM_DAYS,
        num_days: 30,
        date_range: {
          start: DateTime.now().toISO(),
          end: DateTime.now().plus({ months: 1 }).toISO()
        }
      }
    }
  };
};
