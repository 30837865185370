import React, { ReactElement, useCallback, useState } from 'react';
import { Paper, styled } from '@mui/material';
import { MAINTENANCE_GRAPHIC } from '../resourceUrls';
import { DateTime } from 'luxon';
import { getLocalTimeZone } from '../utils/scheduleUtils';
import { useMountEffect } from '../utils/hooks';
import { CabButton } from '@CabComponents/CabButton';

// Check if maintenance is done every 15 minutes
const CHECK_UPDATE_INTERVAL = 1000 * 60 * 15;

const reloadRoot = () => window.location.replace("/");

export const MaintenanceMode = (): ReactElement => {
  const [showRefresh, setShowRefresh] = useState(false);

  const localTz = getLocalTimeZone();
  const localTzAbbr = localTz?.abbreviation || "GMT";
  const localTzName = localTz?.name || "UTC";
  const offsetMinutes = localTz?.currentTimeOffsetInMinutes || 0;
  const offsetHours = offsetMinutes / 60;
  const tzDisplay = `${localTzAbbr} (GMT${offsetHours >= 0 ? '+' : ''}${offsetHours})`;

  const resolutionTime = window.history.state?.expectedResolutionTime || "2023-03-20T03:00:00.000000Z";
  
  let date: DateTime | null = null;
  let dateDisplay = '';

  if (resolutionTime) {
    date = DateTime.fromISO(resolutionTime, { zone: localTzName });
    dateDisplay = date.toFormat('MMM dd yyyy @ hh:mma') + ` ${tzDisplay}`;
  }

  // Check if the current time is greater than expected resolution time and show refresh
  const checkResolutionTime = useCallback(() => {
    if (date && DateTime.local() > date) {
      setShowRefresh(true);
    }
  }, [date]);

  useMountEffect(() => {
    checkResolutionTime();
  });

  // Automatically refresh the root page every so often to pull users out of maintenance if it's done
  setInterval(() => {
    reloadRoot();
  }, CHECK_UPDATE_INTERVAL);

  setInterval(() => {
    checkResolutionTime();
  }, 5000);

  return (
    <Paper sx={{ height: '100%' }}>
      <title>Cabinet is down for maintenance</title>
      <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{display: 'grid', textAlign: 'center', alignItems: 'center'}}>
          <h1>We'll be right back!</h1>
          <StyledImg src={MAINTENANCE_GRAPHIC} alt={'MAINTENANCE'}/>
          {window.history.state?.message
            ? <p>{window.history.state.message}</p>
            : <p>Maintenance on Cabinet is currently in progress. Please check back soon!</p>
          } 
          {showRefresh 
            ? <CabButton buttonType='primary' onClick={reloadRoot} sx={{width: "200px", margin: "auto"}}>
              Refresh Cabinet
            </CabButton>
            : dateDisplay && (
              <p>Cabinet should be up and running by <b>{dateDisplay}</b></p>
            )
          }
        </div>
      </div>
    </Paper>
  );
};

const StyledImg = styled('img', {label: 'StyledImg'})(({ theme }) => ({
  maxHeight: "auto",
  marginRight: "auto",
  marginLeft: "auto",
  textAlign: "center",
  width: "50%",
  height: "auto",
}));

export default MaintenanceMode;