import { ResourcesConfig } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';


export let cookieDomain = 'localhost';
switch (import.meta.env.VITE_DEPLOY_ENV) {
  case 'stage':
    cookieDomain = 'stage.joincabinet.com';
    break;

  case 'demo':
    cookieDomain = 'demo.joincabinet.com';
    break;

  case 'prod':
    cookieDomain = 'joincabinet.com';
    break;
    
  default:
    cookieDomain = 'localhost';
}

type AmplifyAuthConfig = ResourcesConfig & Required<Pick<ResourcesConfig, 'Auth'>>;

export const amplifyAuthConfig: AmplifyAuthConfig = {
  Auth: {
    Cognito: {
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID || '',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID || '',
      loginWith: {
        email: true,
        oauth: {
          domain: import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_OAUTH_DOMAIN || "",
          scopes: (import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_OAUTH_SCOPE || "").split(","),
          redirectSignIn: [import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_OAUTH_REDIRECT_SIGN_IN || ""],
          redirectSignOut: [
            import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_OAUTH_REDIRECT_SIGN_OUT || "",
            ...JSON.parse(import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_CUSTOM_REDIRECT_SIGN_OUT_CUSTOM || "[]")
          ],
          responseType: import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_OAUTH_RESPONSE_TYPE || "",
        }
      },
    }
  }
};

const cookieStorage: Required<ConstructorParameters<typeof CookieStorage>>[0] = {
  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  
  // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  domain: cookieDomain,
  // OPTIONAL - Cookie path
  path: '/',
  // OPTIONAL - Cookie expiration in days
  expires: 30,
  // OPTIONAL - Cookie secure flag
  // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
  secure: process.env.NODE_ENV !== 'development',
};

// This needs to be a function rather than a straight import because we need to be sure window.IS_MOBILE
//   is set before we get the final config, otherwise it's always undefined when we get here
export const getAmplifyConfig = (): AmplifyAuthConfig => {
  // NOTE: This is the earliest any mobile-specific configuration can be done. When this file is first initialized,
  //       the logic that sets window.IS_MOBILE has not yet run.
  const config = amplifyAuthConfig;
  const customSignOutURIs = JSON.parse(
    import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_CUSTOM_REDIRECT_SIGN_OUT_CUSTOM || "[]"
  );

  if (window.IS_NATIVE_ANDROID || window.IS_NATIVE_IOS) {
    // Use deep-link URLs for mobile auth
    const defaultRedirectSignOut = import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_MOBILE_OAUTH_REDIRECT_SIGN_OUT || "";
    const redirectSignIn = [import.meta.env.VITE_AMPLIFY_AUTH_CONFIG_MOBILE_OAUTH_REDIRECT_SIGN_IN || ""];
    const redirectSignOut = [defaultRedirectSignOut, ...customSignOutURIs];
    if (config.Auth.Cognito.loginWith?.oauth) {
      config.Auth.Cognito.loginWith.oauth.redirectSignIn = redirectSignIn;
      config.Auth.Cognito.loginWith.oauth.redirectSignOut = redirectSignOut;
    }
    
    // Use Capacitor Browser plugin for mobile auth
    // NOTE: As of Amplify@6, there is no more "urlOpener" property
    // config.Auth.Cognito.oauth.options = {
    //   urlOpener: async (url, redirectSignInPath) => {
    //     await Browser.open({ url });
    //   }
    // };
  }
  return config;
};

export const getCookieStorage = () => {
  if (window.IS_NATIVE_ANDROID || window.IS_NATIVE_IOS) {
    return null;
  } else {
    return cookieStorage;
  }
};